import { DEFAULT_SITE_URL } from '@/constants';

const SITE_HOSTNAME_REGEX = /^(www\.)?sprinklr\.com/;
const BASE_URL = process.env.SITE_URL ?? DEFAULT_SITE_URL;

function addTrailingSlashToUrl(href: string, baseUrl?: string): URL {
  try {
    const url = new URL(href, baseUrl);
    if (url.pathname.endsWith('/')) {
      return url;
    }
    url.pathname += '/';
    return url;
  } catch (error) {
    throw `Invalid URL: ${href}`;
  }
}

// TODO: Remove this function and ensure all the internal absolute links are stored relatively in contentful
export function checkIfInternalUrl(href: string): boolean {
  try {
    const url = new URL(href);
    return SITE_HOSTNAME_REGEX.test(url.hostname);
  } catch (error) {
    throw `Error while comparing the URLs - ${error} - ${href}`;
  }
}

export function addTrailingSlashToRelativeUrl(href: string) {
  try {
    const url = addTrailingSlashToUrl(href, BASE_URL);
    return url.pathname + url.search + url.hash;
  } catch (error) {
    console.warn(error);
    return href;
  }
}

export function addTrailingSlashIfInternalAbsoluteUrl(href: string): string {
  try {
    const isInternalUrl = checkIfInternalUrl(href);
    if (isInternalUrl) {
      const url = addTrailingSlashToUrl(href);
      return url.toString();
    }
    return href;
  } catch (error) {
    console.warn(error);
    return href;
  }
}
