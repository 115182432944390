// NOTE: The sequence of the keys matter here. Should be lowest to highest resolution
export const BREAKPOINTS = {
  'tablet-sm': '768px',
  tablet: '1024px',
  'desktop-sm': '1280px',
  'desktop-lg': '1728px',
};

export const BREAKPOINTS_IN_NUMBER = {
  'tablet-sm': 768,
  tablet: 1024,
  'desktop-sm': 1280,
  'desktop-lg': 1728,
};
