'use client';
import { ReactNode, useContext, useMemo } from 'react';
import { CTAComponent } from '@/components/widgets/componentProvider/CTAComponent';
import { ImageComponent } from '@/components/widgets/componentProvider/ImageComponent';
import HeaderHeightContext from '@/contexts/HeaderHeightContext';
import { ExternalComponentContextProvider } from '@/external/externalComponent';

const ComponentProvider = ({ children }: { children: ReactNode }) => {
  const [_, setHeaderHeight] = useContext(HeaderHeightContext);
  const contextValue = useMemo(
    () => ({
      CTAComponent: CTAComponent,
      ImageComponent: ImageComponent,
      onResize: setHeaderHeight,
    }),
    [setHeaderHeight],
  );
  return (
    <ExternalComponentContextProvider value={contextValue}>
      {children}
    </ExternalComponentContextProvider>
  );
};

export default ComponentProvider;
