import React, { ReactNode } from 'react';
import { cn } from '@/lib/tailwind';

type Props = {
  className?: string;
  children: ReactNode;
  as?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'span'
    | 'p'
    | 'div'
    | 'ol'
    | 'ul';
};

const MaxWidthContainer = ({ className, children, as = 'div' }: Props) => {
  const As = as;
  return (
    <As
      className={cn(
        'w-full max-w-[312px] tablet-sm:max-w-[688px] desktop-sm:max-w-[930px] desktop-lg:max-w-[1234px] mx-auto',
        className,
      )}
    >
      {children}
    </As>
  );
};

export default MaxWidthContainer;
