import { useRef } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS_IN_NUMBER } from '@/constants/breakpoints';

export type ResponsiveValues<T> = [
  T,
  ...[(T | null)?, (T | null)?, (T | null)?],
];

const useResponsiveValues = <T>(responsiveValues: ResponsiveValues<T>) => {
  const responsiveValuesRef = useRef(responsiveValues);
  const isTabletAndAbove = useMedia(
    `(min-width : ${BREAKPOINTS_IN_NUMBER['tablet-sm']}px)`,
    false,
  );
  const isDesktopSmallAndAbove = useMedia(
    `(min-width : ${BREAKPOINTS_IN_NUMBER['desktop-sm']}px)`,
    false,
  );
  const isDesktopLargeAndAbove = useMedia(
    `(min-width : ${BREAKPOINTS_IN_NUMBER['desktop-lg']}px)`,
    false,
  );

  const responsiveBreakpointState = [
    true,
    isTabletAndAbove,
    isDesktopSmallAndAbove,
    isDesktopLargeAndAbove,
  ];

  return responsiveValuesRef.current.reduce<T>((finalValue, value, index) => {
    if (
      responsiveBreakpointState[index] &&
      value !== null &&
      value !== undefined
    )
      return value;
    return finalValue;
  }, responsiveValuesRef.current[0]);
};

export default useResponsiveValues;
