import { isWindowDefined } from '../isWindowDefined';
import { TACTIC_ID } from './constants';

const LITE_SPRINKLR_URL = 'lite.sprinklr.com';

export const getPersistedTacticId = (): string => {
  if (!isWindowDefined()) return '';
  const tacticId = window.sessionStorage.getItem(TACTIC_ID);
  return tacticId || '';
};

export const setPersistedTacticId = (tacticId: string): void => {
  if (!isWindowDefined()) return;
  window.sessionStorage.setItem(TACTIC_ID, tacticId);
};

export function addTacticIdQueryParam(url: string): string {
  if (!url?.includes(LITE_SPRINKLR_URL)) {
    return url;
  }
  const tactic_id = getPersistedTacticId();
  try {
    const urlObj = new URL(url);
    if (tactic_id) urlObj.searchParams.append(TACTIC_ID, tactic_id);
    return urlObj.toString();
  } catch (e) {
    console.warn('Not a valid url: ', url, e);
    return url;
  }
}

export const isTacticIdPresent = (url: string) => {
  try {
    const urlObj = new URL(url);
    const urlParams = new URLSearchParams(urlObj.search);

    return urlParams.has(TACTIC_ID);
  } catch (e) {
    if (process.env.NODE_ENV !== 'test') {
      console.warn('Not a valid url: ', url, e);
    }

    return false;
  }
};
