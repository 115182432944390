import React from 'react';
import { CTAComponent, ImageComponent } from '../../externalComponent';
import { cn } from '../../tailwind';
import TextLink from '../../TextLink';
import Icon, { IconType } from '../../icon';
import { HighlightItem, MegaMenuSubNavMenuItem } from '../types';

import LinkSection from './LinkSection';

export const HorizontalHighlightBox = ({
  description,
  image,
  cta,
}: HighlightItem) => {
  return (
    <div className="flex gap-5 max-tablet-sm:hidden">
      <div className="w-[140px] relative">
        <ImageComponent
          imageData={image}
          imageClassName="h-full object-contain w-full absolute"
        />
      </div>
      <div className="flex flex-1 flex-col gap-4 h-fit">
        <p className="text-[16px] leading-[1.1] font-semibold">{description}</p>
        <CTAComponent {...cta}>
          <TextLink label={cta.label} />
        </CTAComponent>
      </div>
    </div>
  );
};

const InfoBox = ({ icon, title }: { icon?: IconType; title: string }) => {
  return (
    <div className="max-desktop-sm:pt-8">
      <p className="flex items-center">
        {icon ? <Icon icon={icon} className="h-5 flex-none me-3" /> : null}
        <span className="inline text-mobile-sm/body-2-bold tablet-sm:text-tablet-sm/body-2-bold text-tablet-sm/heading-5 desktop-lg:text-desktop-large/heading-5">
          {title}
        </span>
      </p>
      <div
        className={cn(
          'h-1 rounded-full bg-refreshSkylineBottom',
          'mb-8 tablet-sm:mb-10 desktop-sm:mb-6',
          'mt-4 tablet-sm:mt-6 desktop-sm:mt-5 desktop-lg:mt-4',
        )}
      ></div>
    </div>
  );
};

export const MegaMenuContentDesktop = ({
  item,
  hideSectionHeader = false,
  containerClassName = '',
}: {
  item: MegaMenuSubNavMenuItem;
  hideSectionHeader?: boolean;
  containerClassName?: string;
}) => {
  const { auxLinkGroupTitle, auxLinkGroupLinks } = item;
  const isAuxLinkGroupShown = auxLinkGroupTitle && auxLinkGroupLinks;
  return (
    <div className="relative min-w-[274px] desktop-lg:min-w-[268px] h-full">
      <div className={cn('h-full flex flex-col p-6', containerClassName)}>
        {!hideSectionHeader ? (
          <InfoBox icon={item.titleIcon} title={item.title} />
        ) : null}
        <div className="flex flex-col py-[1px] overflow-y-auto overflow-x-hidden header-scrollbar flex-1 pe-[1px]">
          <LinkSection links={item.links} overviewCta={item.overviewCta} />
          {isAuxLinkGroupShown ? (
            <div
              className={cn('h-full border-t border-gainsboroGray pt-6 mt-6')}
            >
              <p className="text-mediumGrey text-desktop-large/overline-4-bold uppercase mb-[18px]">
                {auxLinkGroupTitle}
              </p>
              <LinkSection links={auxLinkGroupLinks} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const MegaMenuContentMobile = ({
  item,
  hideSectionHeader = false,
  containerClassName,
}: {
  item: MegaMenuSubNavMenuItem;
  hideSectionHeader?: boolean;
  containerClassName?: string;
}) => {
  const { auxLinkGroupTitle, auxLinkGroupLinks } = item;
  const isAuxLinkGroupShown = auxLinkGroupTitle && auxLinkGroupLinks;
  return (
    <div
      className={cn('flex flex-col px-6 tablet-sm:px-10', containerClassName)}
    >
      {!hideSectionHeader ? (
        <InfoBox icon={item.titleIcon} title={item.title} />
      ) : null}
      <div className="pb-4 tablet-sm:pb-10">
        <LinkSection
          links={item.links}
          overviewCta={item.overviewCta}
          variant="MOBILE"
        />
      </div>
      {isAuxLinkGroupShown ? (
        <div
          className={cn(
            'grid grid-cols-1 auto-rows-max py-8 tablet-sm:py-10 border-t border-gainsboroGray',
          )}
        >
          <p className="text-davyGray text-mobile-sm/label-1 tablet-sm:text-tablet-sm/overline-3 uppercase mb-6 tablet-sm:mb-8">
            {auxLinkGroupTitle}
          </p>
          <LinkSection links={auxLinkGroupLinks} variant="MOBILE" />
        </div>
      ) : null}
    </div>
  );
};

const MegaMenuContent = ({
  item,
  variant = 'DESKTOP',
  desktopContainerClassName = '',
}: {
  item: MegaMenuSubNavMenuItem;
  variant?: 'MOBILE' | 'DESKTOP';
  desktopContainerClassName?: string;
}) => {
  return variant === 'MOBILE' ? (
    <MegaMenuContentMobile item={item} />
  ) : (
    <MegaMenuContentDesktop
      item={item}
      containerClassName={desktopContainerClassName}
    />
  );
};

export default MegaMenuContent;
