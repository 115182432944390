'use client';
import { ReactNode, useContext, useLayoutEffect, useRef } from 'react';
import { BREAKPOINTS } from '../constants';
import { cn } from '../tailwind';
import ExternalComponentContext from '../externalComponent';
import useIsHeaderCollapsed from './components/hooks/useIsHeaderCollapsed';

const FIRST_ROW_HEIGHT = 26;
const HEADER_TRANSLATE_OFFSET = `desktop-sm:-translate-y-[26px]`;
const CSS_VAR_HEADER_HEIGHT = '--header-height';

const calculateEffectiveHeaderHeight = (
  headerHeight: number,
  isHeaderCollapsed: boolean,
  isMinimal: boolean,
): number => {
  const isMobileScreenSize = window.matchMedia(
    `not screen and (min-width: ${BREAKPOINTS['desktop-sm']})`,
  ).matches;
  const effectiveHeaderHeight =
    headerHeight -
    (isHeaderCollapsed && !isMobileScreenSize && !isMinimal
      ? FIRST_ROW_HEIGHT
      : 0);
  return effectiveHeaderHeight;
};

export const HeightObserver = ({
  className,
  children,
  isMinimal = false,
}: {
  className?: string;
  children: ReactNode;
  isMinimal?: boolean;
}) => {
  const isHeaderCollapsed = useIsHeaderCollapsed();
  const containerRef = useRef<HTMLDivElement>(null);
  const { onResize } = useContext(ExternalComponentContext);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        const effectiveHeaderHeight = calculateEffectiveHeaderHeight(
          entry.contentRect.height,
          isHeaderCollapsed,
          isMinimal,
        );
        onResize?.(effectiveHeaderHeight);
        containerRef.current?.style.setProperty(
          CSS_VAR_HEADER_HEIGHT,
          `${effectiveHeaderHeight}px`,
        );
      }
    });
    observer.observe(containerRef.current!);
    return () => {
      observer.disconnect();
    };
  }, [isHeaderCollapsed, isMinimal, onResize]);

  return (
    <div
      ref={containerRef}
      className={cn(
        'transition-transform duration-200 w-full',
        !isMinimal && isHeaderCollapsed ? HEADER_TRANSLATE_OFFSET : '',
        className,
      )}
    >
      {children}
    </div>
  );
};
