import linkedInSquareColored from '@/assets/linkedInSquareColored.svg';
import instagramSquareColored from '@/assets/instagramSquareColored.svg';
import glassdoorSquareColored from '@/assets/glassdoorSquareColored.svg';
import service from './assets/service.svg';
import social from './assets/social.svg';
import insights from './assets/insights.svg';
import marketing from './assets/marketing.svg';
import arrow from './assets/arrow.svg';
import chevron from './assets/chevron.svg';
import facebook from './assets/facebook.svg';
import twitter from './assets/twitter.svg';
import linkedin from './assets/linkedin.svg';
import linkedinV2 from './assets/linkedinV2.svg';
import youtube from './assets/youtube.svg';
import instagram from './assets/instagram.svg';
import globe from './assets/globe.svg';
import sprinklrBurst from './assets/sprinklrBurst.svg';
import hamburgerMenu from './assets/hamburgerMenu.svg';
import play from './assets/play.svg';
import playRounded from './assets/playRounded.svg';
import sprinklrFullLogo from './assets/sprinklrFullLogo.svg';
import sprinklrFullLogoDark from './assets/sprinklrFullLogoDark.svg';
import feature from './assets/feature.svg';
import quote from './assets/quote.svg';
import profile from './assets/profile.svg';
import reviewQuote from './assets/reviewQuote.svg';
import home from './assets/home.svg';
import arrowTriangle from './assets/arrow-triangle.svg';
import search from './assets/search.svg';
import resultsNotFound from './assets/resultsNotFound.svg';
import plus from './assets/plus.svg';
import minus from './assets/minus.svg';
import industry from './assets/industry.svg';
import location from './assets/location.svg';
import companySize from './assets/companySize.svg';
import arrowDown from './assets/arrowDown.svg';
import plusRounded from './assets/plusRounded.svg';
import close from './assets/Close.svg';
import videoPlayer from './assets/video-player.svg';
import sprinklrName from './assets/sprinklrName.svg';
import check from './assets/check.svg';
import amazonMusic from './assets/amazon-music.svg';
import apple from './assets/apple.svg';
import googlePodcast from './assets/google-podcast.svg';
import spotify from './assets/spotify.svg';
import youtubeMusic from './assets/youtube-music.svg';
import clock from './assets/clock.svg';
import duration from './assets/duration.svg';
import searchV2 from './assets/searchV2.svg';
import playCircle from './assets/play-circle.svg';
import share from './assets/share.svg';
import playV2 from './assets/playV2.svg';
import link from './assets/link.svg';
import arrowLeft from './assets/arrow-left.svg';
import arrowRight from './assets/arrow-right.svg';
import crossProduct from './assets/cross-product.svg';
import closeCross from './assets/close-cross.svg';
import tickCircle from './assets/tick-circle.svg';
import mailEnvelope from './assets/mailEnvelope.svg';
import document from './assets/document.svg';
import sprinklrBurstMonotone from './assets/sprinklr-burst-monotone.svg';
import organization from './assets/organization.svg';
import refresh from './assets/refresh.svg';
import magic from './assets/magic.svg';
import stats from './assets/stats.svg';
import authorPlaceholder from './assets/author-placeholder.svg';
import roundedCheckbox from './assets/rounded-checkbox.svg';
import back from './assets/back.svg';
import dropDown from './assets/dropdown.svg';
import addSlim from './assets/add-slim.svg';
import minusSlim from './assets/minus-slim.svg';
import documentSpanner from './assets/document-spanner.svg';
import ideaGearBulb from './assets/idea-gear-bulb.svg';
import star from './assets/star.svg';
import funnel from './assets/funnel.svg';
import g2 from './assets/g2.svg';

const iconMap = {
  service,
  social,
  insights,
  marketing,
  arrow,
  chevron,
  facebook,
  twitter,
  linkedin,
  youtube,
  instagram,
  globe,
  sprinklrBurst,
  hamburgerMenu,
  play,
  sprinklrFullLogo,
  sprinklrFullLogoDark,
  feature,
  quote,
  profile,
  reviewQuote,
  home,
  arrowTriangle,
  search,
  resultsNotFound,
  plus,
  minus,
  industry,
  location,
  companySize,
  arrowDown,
  plusRounded,
  close,
  linkedinV2,
  videoPlayer,
  sprinklrName,
  check,
  tickCircle,
  playRounded,
  amazonMusic,
  googlePodcast,
  spotify,
  apple,
  youtubeMusic,
  clock,
  duration,
  searchV2,
  playCircle,
  share,
  mailEnvelope,
  playV2,
  link,
  arrowLeft,
  arrowRight,
  crossProduct,
  closeCross,
  linkedInSquareColored,
  instagramSquareColored,
  glassdoorSquareColored,
  document,
  sprinklrBurstMonotone,
  organization,
  refresh,
  magic,
  stats,
  authorPlaceholder,
  roundedCheckbox,
  back,
  dropDown,
  addSlim,
  minusSlim,
  documentSpanner,
  ideaGearBulb,
  star,
  funnel,
  g2,
};

export type IconType = keyof typeof iconMap;

type Props = {
  icon: IconType;
  alt?: string;
  className?: string;
};

const Icon = ({ icon, className }: Props) => {
  const Component = iconMap[icon];
  return Component ? <Component className={className} /> : null;
};

export default Icon;
