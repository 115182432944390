'use client';
import React, { useState } from 'react';
import { CTAComponent } from '../../externalComponent/ExternalComponent';
import {
  HoverCard,
  HoverCardArrow,
  HoverCardContent,
  HoverCardTrigger,
} from '../../hover-card';
import { cn } from '../../tailwind';
import { LanguageSelectorProps } from '../types';
import Icon from '../../icon';
import { languageMap } from '../languageMapping';
import { LANGUAGE_CONFIG } from '../constants';

const DEFAULT_PADDING = {
  left: 12,
  right: 12,
};

const isValidLocale = (
  locale: string,
): locale is (typeof LANGUAGE_CONFIG)[number]['locale'] => {
  return LANGUAGE_CONFIG.map(config => config.locale).includes(locale as any);
};

const Desktop = ({
  activeLocale,
  translations,
  children,
  isDark = false,
  variant = 'FOOTER',
  containerClassName,
}: LanguageSelectorProps) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const filteredLanguageConfigs = LANGUAGE_CONFIG.filter(config =>
    translations.map(translation => translation.locale).includes(config.locale),
  );
  const activeLangLabel =
    (activeLocale &&
      isValidLocale(activeLocale) &&
      languageMap[activeLocale]?.label) ||
    'LANGUAGE';
  return (
    <div className={cn(isDark ? 'dark' : '', containerClassName)}>
      <HoverCard
        open={isMenuExpanded}
        onOpenChange={setIsMenuExpanded}
        openDelay={0}
      >
        <HoverCardTrigger
          asChild
          onTouchEnd={() => setIsMenuExpanded(!isMenuExpanded)}
        >
          {children ? (
            children
          ) : (
            <div className="flex items-center">
              <span
                className={cn(
                  'uppercase  cursor-pointer',
                  variant === 'HEADER'
                    ? 'desktop-sm:text-tablet-sm/overline-4 desktop-lg:text-desktop-large/overline-4 text-cometBlack'
                    : 'text-desktop/subhead-m',
                )}
              >
                {activeLangLabel}
              </span>
              <Icon
                icon="chevron"
                className={cn(
                  'inline-block ms-2.5 duration-75 h-3.5',
                  isDark ? 'fill-white' : 'fill-cometBlack',
                  isMenuExpanded && 'rotate-180',
                )}
              />
            </div>
          )}
        </HoverCardTrigger>
        <HoverCardContent
          collisionPadding={DEFAULT_PADDING}
          sideOffset={16}
          className="px-0 py-5 w-[165px] bg-white dark:bg-primarySpaceGrey overflow-hidden rounded data-[state=closed]:hidden"
          forceMount
        >
          <ul className="w-full flex flex-col ps-4 gap-y-6">
            {filteredLanguageConfigs.map(({ locale, lang }) => {
              const languageData = languageMap[locale];
              const { cta } = translations.find(
                translation => translation.locale === locale,
              )!;

              if (!languageData) {
                return null;
              }

              const { label, imageUrl } = languageData;
              return (
                <li key={lang} className="block">
                  <CTAComponent {...cta}>
                    <div className="flex items-center gap-3 w-full">
                      {/* Disabling since this is an external component */}
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img src={imageUrl} alt={label} width={18} height={10} />
                      <div className="text-desktop/subhead-xs uppercase hover:text-primaryOceanBlue">
                        {label}
                      </div>
                    </div>
                  </CTAComponent>
                </li>
              );
            })}
          </ul>
          <HoverCardArrow className="h-4 w-[22px] block absolute fill-white bg-white dark:bg-primarySpaceGrey dark:fill-primarySpaceGrey transform rotate-[225deg] -translate-x-1/2 left-3/4 top-[-10px] shadow-tooltip" />
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};

export default Desktop;
