'use client';
import { ReactElement, ReactNode } from 'react';
import ClickAction from '@/components/CallToAction/behaviour/ClickAction';
import LinkAction from '@/components/CallToAction/behaviour/LinkAction';
import { Cta } from '@/external/types';
import { CtaData } from '@/types';

export const CTAComponent = ({
  children,
  ...ctaProps
}: Cta & { children: ReactNode }): ReactElement => {
  const BehaviourComponent =
    (ctaProps as CtaData).action === 'NAVIGATE' ? LinkAction : ClickAction;
  return (
    <BehaviourComponent {...(ctaProps as CtaData)}>
      {children}
    </BehaviourComponent>
  );
};
