'use client';
import React, {
  useEffect,
  useRef,
  CSSProperties,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { cn } from '../../../tailwind';
import { CTAComponent } from '../../../externalComponent';
import { useIsMobile } from '../hooks/useIsMobile';
import { LanguageSelector } from '../../../languageSelector/LanguageSelector';
import { LayoutProps } from '../../types';
import MenuItemList from './components/MenuItemList';
import { useInnerHeight } from './useInnerHeight';
import MenuLabel from './components/MenuLabel';

const MobileMenu = ({
  navItems,
  loginCta,
  secondaryCta,
  auxCta,
  supportCta,
  languageSelector,
}: LayoutProps) => {
  const [isMenuScrollDisabled, setIsMenuScrollDisabled] = useState(false);
  const handleSubMenuOverlayToggled = useCallback(
    (isSubMenuOverlayOpen: boolean) =>
      setIsMenuScrollDisabled(isSubMenuOverlayOpen),
    [setIsMenuScrollDisabled],
  );
  const isMobile = useIsMobile();
  const { innerHeight } = useInnerHeight();
  const bottomPanelRef = useRef<HTMLDivElement | null>(null);
  const [bottomPanelHeight, setBottomPanelHeight] = useState(0);

  useEffect(() => {
    if (bottomPanelRef.current) {
      setBottomPanelHeight(bottomPanelRef.current.offsetHeight);
    }
  }, []);

  const overlayStyles = useMemo<CSSProperties>(
    () => ({
      ['--fullscreen-menu-height' as string]: `calc(${innerHeight}px - 100%)`,
      ['--fullscreen-menu-content-height' as string]: `calc(${innerHeight}px - var(--header-height) - ${bottomPanelHeight}px)`,
    }),
    [innerHeight, bottomPanelHeight],
  );

  const secondaryCTAs = isMobile
    ? [loginCta, auxCta]
    : [loginCta, auxCta, supportCta];

  return (
    <div
      className={cn(
        'absolute overflow-x-clip w-full bg-white h-[--fullscreen-menu-height] flex flex-col',
        'tablet-sm:border-t tablet-sm:border-t-baseBlack/10 tablet-sm:shadow-menu',
      )}
      style={overlayStyles}
    >
      <div
        className={cn(
          'w-full flex overflow-x-clip flex-col max-tablet-sm:border-t max-tablet-sm:border-t-baseBlack/10 flex-1',
          isMenuScrollDisabled ? 'overflow-hidden' : 'overflow-auto',
        )}
      >
        <MenuItemList
          navItems={navItems}
          onSubMenuOverlayToggled={handleSubMenuOverlayToggled}
        />
        {secondaryCta ? (
          <div className="border-b border-b-baseBlack/10 mx-6 tablet-sm:mx-10">
            <CTAComponent {...secondaryCta}>
              <MenuLabel label={secondaryCta?.label} />
            </CTAComponent>
          </div>
        ) : null}

        {isMobile && supportCta ? (
          <div className="mx-6 tablet-sm:mx-10">
            <CTAComponent {...supportCta}>
              <MenuLabel label={supportCta?.label} />
            </CTAComponent>
          </div>
        ) : null}

        <div className="basis-full flex flex-col justify-end mx-6 tablet-sm:mx-10 tablet-sm:justify-start">
          {languageSelector && languageSelector.translations.length > 1 ? (
            <LanguageSelector variant="MOBILE" {...languageSelector} />
          ) : null}
        </div>
      </div>
      <div
        ref={bottomPanelRef}
        className={cn(
          'w-full flex items-center justify-between shrink-0',
          'border-t border-t-baseBlack/10',
          'px-5 pt-6 pb-8 tablet-sm:pt-8 tablet-sm:px-10',
        )}
      >
        {secondaryCTAs.map(cta => {
          return cta ? (
            <CTAComponent {...cta}>
              <span className="text-mobile-sm/overline-2 tablet-sm:text-tablet-sm/overline-1 uppercase">
                {cta.label}
              </span>
            </CTAComponent>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default MobileMenu;
