'use client';
import { useLayoutEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import useResponsiveValues from '@/hooks/useResponsiveValues';
import { cn } from '../../tailwind';
import { CTAComponent } from '../../externalComponent';
import { Cta } from '../../types';

export const Link = ({ link }: { link: Cta }) => {
  /**
   * Adding max-width causes extra whitespace when text wraps.
   * Ensure width(element) = width(wrapped text content) here.
   * TODO: Look for CSS-only solutions.
   */
  const ref = useRef<HTMLSpanElement | null>(null);
  const [styles, setStyles] = useState<{ width?: string }>({});
  const shouldSetWidthValue = useResponsiveValues([0, 0, 1, 2]);
  const prevShouldSetWidth = usePrevious(shouldSetWidthValue);
  const shouldResetWidth = prevShouldSetWidth !== shouldSetWidthValue;
  const shouldSetWidth = shouldSetWidthValue > 0;
  useLayoutEffect(() => {
    if (ref.current && shouldSetWidth && !shouldResetWidth) {
      const range = document.createRange();
      const text = ref.current.childNodes[0];
      range.setStartBefore(text);
      range.setEndAfter(text);
      const clientRect = range.getBoundingClientRect();
      setStyles({
        width: `${clientRect.width}px`,
      });
    } else if (shouldResetWidth) {
      setStyles({
        width: undefined,
      });
    }
  }, [shouldSetWidth, shouldResetWidth]);

  return (
    <CTAComponent key={link.label} {...link}>
      <span
        ref={ref}
        style={styles}
        className={cn(
          'block w-fit desktop-sm:max-w-[120px] desktop-lg:max-w-[180px]',
          'text-mobile-sm/body-3 tablet-sm:text-tablet-sm/body-3 desktop-sm:text-tablet-sm/body-5 desktop-lg:text-desktop-large/body-4',
          'hover:underline',
        )}
      >
        {link.label}
      </span>
    </CTAComponent>
  );
};
