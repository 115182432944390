'use client';

import { AnchorHTMLAttributes, useEffect, useState } from 'react';
import {
  addTacticIdQueryParam,
  isTacticIdPresent,
} from '@/utils/tacticId/utils';
import { Props } from './types';

const OPEN_NEW_TAB_ATTRIBUTES: AnchorHTMLAttributes<HTMLAnchorElement> = {
  target: '_blank',
  rel: 'noreferrer noopener',
};

const SEO_IGNORED_ATTRIBUTES: AnchorHTMLAttributes<HTMLAnchorElement> = {
  target: '_blank',
  rel: 'noreferrer noopener nofollow',
};

const ExternalLink = ({
  href,
  isOpenNewTab,
  isExternalLinkIgnoredForSEO,
  ...props
}: Props) => {
  const [url, setUrl] = useState(href);

  useEffect(() => {
    if (!isTacticIdPresent(url)) {
      setUrl(addTacticIdQueryParam(url));
    }
  }, [url]);

  const additionalProps = {
    ...(isOpenNewTab ? OPEN_NEW_TAB_ATTRIBUTES : undefined),
    ...(isExternalLinkIgnoredForSEO ? SEO_IGNORED_ATTRIBUTES : undefined),
  };

  return (
    <a data-link-type="external" href={url} {...props} {...additionalProps} />
  );
};

export default ExternalLink;
