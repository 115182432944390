import Image, { ImageProps } from 'next/image';
import { Asset } from '@/types';

type Props = {
  asset: Asset;
  title: string;
  fill?: boolean;
};

const ImageAssetBlock = ({
  asset,
  fill = false,
  title,
  className,
  ...imageProps
}: Props & Omit<ImageProps, 'src' | 'alt' | 'width' | 'height' | 'fill'>) => {
  return (
    <Image
      src={asset.file.url}
      alt={title}
      fill={fill}
      width={fill ? undefined : asset.file.details.image.width}
      height={fill ? undefined : asset.file.details.image.height}
      className={className}
      {...imageProps}
    />
  );
};

export default ImageAssetBlock;
