import { BREAKPOINTS_IN_NUMBER } from '@/constants/breakpoints';
import {
  DESKTOP_LARGE_CURVE_HEIGHT,
  DESKTOP_SMALL_CURVE_HEIGHT,
  TABLET_CURVE_HEIGHT,
  MOBILE_CURVE_HEIGHT,
} from '@/constants/curveHeightStyles';

const CURVED_HEIGHT_TO_CONTROL_POINT_FACTOR = 1.3;

const getCurveHeightForWidth = (width: number): number => {
  if (width >= BREAKPOINTS_IN_NUMBER['desktop-lg']) {
    return DESKTOP_LARGE_CURVE_HEIGHT;
  } else if (width >= BREAKPOINTS_IN_NUMBER['desktop-sm']) {
    return DESKTOP_SMALL_CURVE_HEIGHT;
  } else if (width >= BREAKPOINTS_IN_NUMBER['tablet-sm']) {
    return TABLET_CURVE_HEIGHT;
  } else {
    return MOBILE_CURVE_HEIGHT;
  }
};

export const getClipPathDefinition = (
  width: number,
  height: number,
): string => {
  const controlPointY =
    getCurveHeightForWidth(width) * CURVED_HEIGHT_TO_CONTROL_POINT_FACTOR;
  const controlPointX = width / 3;
  return `M 0 0 C ${controlPointX} ${controlPointY} ${width - controlPointX}
  ${controlPointY} ${width} 0 L ${width} ${height} L 0 ${height} L 0 0`;
};
