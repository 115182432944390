'use client';
import ImageBlock from '@/components/ImageBlock';
import { ImageData } from '@/types';

export const ImageComponent = ({
  imageData,
  imageClassName,
}: {
  imageData: ImageData;
  imageClassName?: string;
}) => {
  return <ImageBlock image={imageData} className={imageClassName} />;
};
