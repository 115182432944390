'use client';
import _throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';

const SCROLL_OFFSET_THRESHOLD = 150;

const useIsHeaderCollapsed = (): boolean => {
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);

  useEffect(() => {
    const handleScroll = _throttle(() => {
      setIsHeaderCollapsed(window.scrollY > SCROLL_OFFSET_THRESHOLD);
    }, 80);

    handleScroll();

    window.addEventListener('scroll', handleScroll, {
      capture: false,
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isHeaderCollapsed;
};

export default useIsHeaderCollapsed;
