import React from 'react';
import Icon, { IconType } from '../../../../../icon';
import { cn } from '../../../../../tailwind';

type MenuLabelVariant = 'PRIMARY' | 'SECONDARY';

type Props = {
  label: string;
  variant?: MenuLabelVariant;
  icon?: IconType;
  isDropdown?: boolean;
  isActive?: boolean;
  isUnderlined?: boolean;
  onHover?: () => void;
};

const MenuLabel = ({
  label,
  variant = 'PRIMARY',
  icon,
  isActive = false,
  isDropdown = false,
  onHover,
}: Props) => {
  return (
    <div
      className={cn('flex items-center gap-2 desktop-lg:gap-2.5 w-max')}
      onMouseEnter={onHover}
    >
      {icon ? <Icon icon={icon} className="h-3.5" /> : null}
      <span
        className={cn(
          'block uppercase py-0 desktop-sm:text-tablet-sm/overline-4 desktop-lg:text-desktop-large/overline-4',
          variant === 'SECONDARY'
            ? 'text-cometBlack hover:text-cometBlack/80'
            : 'text-primarySpaceGrey hover:text-primaryOceanBlue',
          isActive ? 'text-primaryOceanBlue' : '',
        )}
        data-text={label}
      >
        {label}
      </span>
      {isDropdown ? (
        <Icon
          icon="chevron"
          className={cn(
            'h-[14px] fill-primarySpaceGrey',
            isActive ? 'rotate-180 fill-primaryOceanBlue' : '',
          )}
        />
      ) : null}
    </div>
  );
};

export default MenuLabel;
