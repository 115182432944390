import { ModalSize } from './types';

export const SHARE_LINKS_CONTENT_TYPE = 'shareLinks';

export const MODAL_SIZE_MAP: {
  [key: string]: ModalSize;
} = {
  templateForm: ModalSize.FULL,
  video: ModalSize.FULL,
  widgetLogoGallery: ModalSize.FULL,
  [SHARE_LINKS_CONTENT_TYPE]: ModalSize.COMPACT,
};

export const VIDEO_CONTENT_TYPE = 'video' as const;
