'use client';
import React, { ReactNode, useRef } from 'react';
import { useClickAway } from 'react-use';
import { cn } from '@/lib/tailwind';
import useIsHeaderCollapsed from '../../hooks/useIsHeaderCollapsed';

export const MenuListContainer = ({
  onClickAway,
  children,
}: {
  onClickAway: () => void;
  children: ReactNode;
}) => {
  const menuRef = useRef<HTMLUListElement>(null);

  useClickAway(
    menuRef,
    () => {
      onClickAway();
    },
    ['mouseover'],
  );

  const isHeaderCollapsed = useIsHeaderCollapsed();

  return (
    <ul
      className={cn(
        'relative flex h-full gap-4 desktop-lg:gap-8 transition-transform duration-400 ease-in-out',
        isHeaderCollapsed
          ? '-translate-x-[84px] desktop-lg:-translate-x-[111px] delay-100'
          : '',
      )}
      ref={menuRef}
    >
      {children}
    </ul>
  );
};
