'use client';
import { memo, useCallback, useState } from 'react';
import { NavItem } from '../../types';
import MenuDropdownWrapper from './components/MenuDropdownWrapper/MenuDropdownWrapper';
import MenuItem from './components/MenuItem';
import { MenuListContainer } from './components/MenuListContainer';

export type Props = {
  items: NavItem[];
};

const Menu = ({ items }: Props) => {
  const [activeMenuItemLabel, setActiveMenuItemLabel] = useState<string | null>(
    null,
  );

  const handleHover = useCallback(
    (label: string) => {
      setActiveMenuItemLabel(label);
    },
    [setActiveMenuItemLabel],
  );

  const handleMenuContainerClickAway = useCallback(() => {
    setActiveMenuItemLabel(null);
  }, []);
  return (
    <MenuDropdownWrapper activeMenuItemLabel={activeMenuItemLabel}>
      <MenuListContainer onClickAway={handleMenuContainerClickAway}>
        {items.map(item => {
          const isHovered = activeMenuItemLabel === item.label;
          return (
            <MenuItem
              key={item.label}
              {...item}
              isHovered={isHovered}
              onHover={handleHover}
            />
          );
        })}
      </MenuListContainer>
    </MenuDropdownWrapper>
  );
};

export default memo(Menu);
