import dynamic, { LoaderComponent } from 'next/dynamic';
import { SVGProps as SVGElementProps } from 'react';

type SVGProps = SVGElementProps<SVGSVGElement>;

const dynamicSVG = (importCallback: () => LoaderComponent<SVGProps>) =>
  dynamic<SVGProps>(importCallback);

const service = dynamicSVG(() => import('@/assets/service.svg'));
const social = dynamicSVG(() => import('@/assets/social.svg'));
const insights = dynamicSVG(() => import('@/assets/insights.svg'));
const marketing = dynamicSVG(() => import('@/assets/marketing.svg'));
const arrow = dynamicSVG(() => import('@/assets/arrow.svg'));
const chevron = dynamicSVG(() => import('@/assets/chevron.svg'));
const facebook = dynamicSVG(() => import('@/assets/facebook.svg'));
const twitter = dynamicSVG(() => import('@/assets/twitter.svg'));
const linkedin = dynamicSVG(() => import('@/assets/linkedin.svg'));
const youtube = dynamicSVG(() => import('@/assets/youtube.svg'));
const instagram = dynamicSVG(() => import('@/assets/instagram.svg'));
const globe = dynamicSVG(() => import('@/assets/globe.svg'));
const sprinklrBurst = dynamicSVG(() => import('@/assets/sprinklrBurst.svg'));
const hamburgerMenu = dynamicSVG(() => import('@/assets/hamburgerMenu.svg'));
const play = dynamicSVG(() => import('@/assets/play.svg'));
const sprinklrFullLogoDark = dynamicSVG(
  () => import('@/assets/sprinklrFullLogoDark.svg'),
);
const feature = dynamicSVG(() => import('@/assets/feature.svg'));
const quote = dynamicSVG(() => import('@/assets/quote.svg'));
const profile = dynamicSVG(() => import('@/assets/profile.svg'));
const reviewQuote = dynamicSVG(() => import('@/assets/reviewQuote.svg'));
const home = dynamicSVG(() => import('@/assets/home.svg'));
const arrowTriangle = dynamicSVG(() => import('@/assets/arrow-triangle.svg'));
const search = dynamicSVG(() => import('@/assets/search.svg'));
const resultsNotFound = dynamicSVG(
  () => import('@/assets/resultsNotFound.svg'),
);
const plus = dynamicSVG(() => import('@/assets/plus.svg'));
const minus = dynamicSVG(() => import('@/assets/minus.svg'));
const industry = dynamicSVG(() => import('@/assets/industry.svg'));
const location = dynamicSVG(() => import('@/assets/location.svg'));
const companySize = dynamicSVG(() => import('@/assets/companySize.svg'));
const arrowDown = dynamicSVG(() => import('@/assets/arrowDown.svg'));
const plusRounded = dynamicSVG(() => import('@/assets/plusRounded.svg'));
const close = dynamicSVG(() => import('@/assets/close.svg'));
const linkedinV2 = dynamicSVG(() => import('@/assets/linkedinV2.svg'));
const videoPlayer = dynamicSVG(() => import('@/assets/video-player.svg'));
const check = dynamicSVG(() => import('@/assets/check.svg'));
const tickCircle = dynamicSVG(() => import('@/assets/tick-circle.svg'));
const amazonMusic = dynamicSVG(() => import('@/assets/amazon-music.svg'));
const apple = dynamicSVG(() => import('@/assets/apple.svg'));
const googlePodcast = dynamicSVG(() => import('@/assets/google-podcast.svg'));
const spotify = dynamicSVG(() => import('@/assets/spotify.svg'));
const youtubeMusic = dynamicSVG(() => import('@/assets/youtube-music.svg'));
const clock = dynamicSVG(() => import('@/assets/clock.svg'));
const duration = dynamicSVG(() => import('@/assets/duration.svg'));
const searchV2 = dynamicSVG(() => import('@/assets/searchV2.svg'));
const playCircle = dynamicSVG(() => import('@/assets/play-circle.svg'));
const share = dynamicSVG(() => import('@/assets/share.svg'));
const mailEnvelope = dynamicSVG(() => import('@/assets/mailEnvelope.svg'));
const link = dynamicSVG(() => import('@/assets/link.svg'));
const playV2 = dynamicSVG(() => import('@/assets/playV2.svg'));
const arrowLeft = dynamicSVG(() => import('@/assets/arrow-left.svg'));
const arrowRight = dynamicSVG(() => import('@/assets/arrow-right.svg'));
const closeCross = dynamicSVG(() => import('@/assets/close-cross.svg'));
const playRounded = dynamicSVG(() => import('@/assets/playRounded.svg'));
const crossProduct = dynamicSVG(() => import('@/assets/cross-product.svg'));
const linkedInSquareColored = dynamicSVG(
  () => import('@/assets/linkedInSquareColored.svg'),
);
const instagramSquareColored = dynamicSVG(
  () => import('@/assets/instagramSquareColored.svg'),
);
const glassdoorSquareColored = dynamicSVG(
  () => import('@/assets/glassdoorSquareColored.svg'),
);
const document = dynamicSVG(() => import('@/assets/document.svg'));
const sprinklrBurstMonotone = dynamicSVG(
  () => import('@/assets/sprinklr-burst-monotone.svg'),
);
const organization = dynamicSVG(() => import('@/assets/organization.svg'));
const refresh = dynamicSVG(() => import('@/assets/refresh.svg'));
const magic = dynamicSVG(() => import('@/assets/magic.svg'));
const stats = dynamicSVG(() => import('@/assets/stats.svg'));
const authorPlaceholder = dynamicSVG(
  () => import('@/assets/author-placeholder.svg'),
);
const roundedCheckbox = dynamicSVG(
  () => import('@/assets/rounded-checkbox.svg'),
);
const back = dynamicSVG(() => import('@/assets/back.svg'));
const dropDown = dynamicSVG(() => import('@/assets/dropdown.svg'));
const addSlim = dynamicSVG(() => import('@/assets/add-slim.svg'));
const minusSlim = dynamicSVG(() => import('@/assets/minus-slim.svg'));
const documentSpanner = dynamicSVG(
  () => import('@/assets/document-spanner.svg'),
);
const ideaGearBulb = dynamicSVG(() => import('@/assets/idea-gear-bulb.svg'));
const star = dynamicSVG(() => import('@/assets/star.svg'));
const funnel = dynamicSVG(() => import('@/assets/funnel.svg'));
const g2 = dynamicSVG(() => import('@/assets/g2.svg'));

const iconMap = {
  service,
  social,
  insights,
  marketing,
  arrow,
  chevron,
  facebook,
  twitter,
  linkedin,
  linkedinV2,
  youtube,
  instagram,
  globe,
  sprinklrBurst,
  hamburgerMenu,
  play,
  sprinklrFullLogoDark,
  feature,
  quote,
  profile,
  reviewQuote,
  home,
  arrowTriangle,
  search,
  searchV2,
  resultsNotFound,
  plus,
  minus,
  industry,
  location,
  companySize,
  arrowDown,
  plusRounded,
  close,
  videoPlayer,
  check,
  tickCircle,
  playRounded,
  amazonMusic,
  googlePodcast,
  spotify,
  apple,
  youtubeMusic,
  clock,
  duration,
  playCircle,
  share,
  mailEnvelope,
  link,
  playV2,
  // TODO: use transform property to generate arrowRight from arrowLeft
  arrowLeft,
  arrowRight,
  closeCross,
  crossProduct,
  linkedInSquareColored,
  instagramSquareColored,
  glassdoorSquareColored,
  document,
  sprinklrBurstMonotone,
  organization,
  refresh,
  magic,
  stats,
  authorPlaceholder,
  roundedCheckbox,
  back,
  dropDown,
  addSlim,
  minusSlim,
  ideaGearBulb,
  documentSpanner,
  star,
  funnel,
  g2,
};

export type IconType = keyof typeof iconMap;

type Props = SVGProps & {
  icon: IconType;
  alt?: string;
  className?: string;
};

const Icon = ({ icon, className, ...props }: Props) => {
  const Component = iconMap[icon];
  return Component ? <Component {...props} className={className} /> : null;
};

export default Icon;
