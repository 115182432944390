import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from './tailwind';

const buttonVariants = cva(
  'inline-flex items-center justify-center text-center rounded-full ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 cursor-pointer',
  {
    variants: {
      variant: {
        bare: 'block rounded-none',
        primary:
          'bg-primaryOceanBlue text-white hover:bg-prussianBlue active:bg-midnightBlue',
        secondary:
          'border border-solid bg-white border-primaryOceanBlue text-primaryOceanBlue hover:text-prussianBlue hover:border-prussianBlue active:text-midnightBlue active:border-midnightBlue',
        link: 'text-black underline-offset-4 hover:underline uppercase font-semibold',
        light: 'bg-white text-[#177CBA] hover:bg-white/90 ring-offset-black',
        /* TODO: Assign a better name to this variant */
        blueBorder:
          'bg-white text-sky-600 border-2 border-sky-600 hover:bg-white/90 focus-visible:ring-blue-500',
      },
      size: {
        default:
          'uppercase text-mobile-sm/overline-2 px-6 py-1.5 gap-6 tablet-sm:text-tablet-sm/overline-3 tablet-sm:px-8 tablet-sm:py-2 desktop-lg:text-desktop-large/overline-3-bold desktop-lg:py-3',
        sm: 'py-1.5 px-5 text-[12px] font-semibold',
        lg: 'h',
        icon: 'h-10 w-10',
        bare: '',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
