export const joinPathSegments = (...paths: string[]): string => {
  const finalPath = paths.reduce((mergedPath, path) => {
    const sanitizedPath = path.replace(/(^\/+|\/+$)/g, '');
    if (sanitizedPath.length === 0) {
      return mergedPath;
    }

    if (mergedPath.length === 0) {
      return sanitizedPath;
    }

    return `${mergedPath}/${sanitizedPath}`;
  }, '');

  return finalPath === '' ? '/' : `/${finalPath}/`;
};
