'use client';
import { useLayoutEffect, useState, useMemo } from 'react';
import _throttle from 'lodash.throttle';

const DEFAULT_THRESHOLD = 5;

export const useLastScrollToDirection = ({
  threshold,
}: {
  threshold: number;
}) => {
  const [state, setState] = useState<{
    direction: 'NONE' | 'BOTTOM' | 'TOP';
    y: number;
  }>({ direction: 'NONE', y: 0 });

  // https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
  const throttledHandleScroll = useMemo(
    () =>
      _throttle((): void => {
        setState(prev =>
          Math.abs(window.scrollY - prev.y) < (threshold ?? DEFAULT_THRESHOLD)
            ? prev
            : {
                y: window.scrollY,
                direction: window.scrollY > prev.y ? 'BOTTOM' : 'TOP',
              },
        );
      }, 100),
    [threshold],
  );

  useLayoutEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);

    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, [throttledHandleScroll]);

  return state.direction;
};
