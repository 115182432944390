import React from 'react';
import { cn } from './tailwind';
import Text from './header/components/text';

export const OverviewTextLink = ({
  className,
  label,
}: {
  label: string;
  className?: string;
}) => {
  return (
    <Text
      as="p"
      className={cn(
        'text-mobile-sm/body-2-bold tablet-sm:text-tablet-sm/body-3-bold desktop-sm:text-tablet-sm/body-4-bold desktop-lg:text-desktop-large/body-3-bold hover:text-primaryOceanBlue',
        className,
      )}
    >
      {label}
    </Text>
  );
};
