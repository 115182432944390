import { useEffect, useState } from 'react';
import { CtaAction, NavatticDemoConfig } from '../types';

const NAVATTIC_EMBED_SCRIPT_SRC = 'https://js.navattic.com/embeds.js';

function createNavatticScript() {
  const navatticScript = document.createElement('script');
  navatticScript.src = NAVATTIC_EMBED_SCRIPT_SRC;
  navatticScript.defer = true;
  navatticScript.async = true;

  document.body.appendChild(navatticScript);

  return navatticScript;
}

const useNavatticDemoLoader = (
  navatticDemoConfig?: NavatticDemoConfig,
  action?: CtaAction,
) => {
  const [isScriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (action === 'OPEN_NAVATTIC_DEMO' && navatticDemoConfig) {
      const handleScriptLoad = () => {
        setScriptLoaded(true);
      };

      const navatticScriptElement =
        document.querySelector(`script[src="${NAVATTIC_EMBED_SCRIPT_SRC}"]`) ||
        createNavatticScript();

      if (window.NavatticEmbed) {
        handleScriptLoad();
      } else {
        navatticScriptElement.addEventListener('load', handleScriptLoad);
      }

      return () => {
        navatticScriptElement.removeEventListener('load', handleScriptLoad);
      };
    }
  }, [action, navatticDemoConfig]);

  return isScriptLoaded;
};

export default useNavatticDemoLoader;
