import React, { useMemo, type ElementType } from 'react';
import NextLink from 'next/link';

import { isRelativeURL } from '@/utils/isRelativeURL';
import {
  addTrailingSlashToRelativeUrl,
  addTrailingSlashIfInternalAbsoluteUrl,
} from '@/utils/addTrailingSlash';

import { isSSGEnabled } from '@/utils/isSSGEnabled';
import ExternalLink from './ExternalLink';
import { Props } from './types';
import { isNextJsWebsiteLink } from './utils';

const OPEN_NEW_TAB_ATTRS = { target: '_blank', rel: 'noreferrer noopener' };

const Link = ({ href, external = false, isOpenNewTab, ...props }: Props) => {
  const isRelativeLink = isRelativeURL(href);
  const isExternalLink = external || !isRelativeLink;
  const updatedHref = useMemo(
    () =>
      isExternalLink
        ? addTrailingSlashIfInternalAbsoluteUrl(href)
        : addTrailingSlashToRelativeUrl(href),
    [href, isExternalLink],
  );
  const isNextJsInternalLink = isNextJsWebsiteLink(updatedHref);
  const additionalProps = {
    ...(isOpenNewTab ? OPEN_NEW_TAB_ATTRS : undefined),
    ...(!isExternalLink && isNextJsInternalLink
      ? {
          prefetch: isSSGEnabled(),
        }
      : undefined),
  };

  const Comp: ElementType = isExternalLink
    ? ExternalLink
    : isNextJsInternalLink
    ? NextLink
    : 'a';

  return <Comp href={updatedHref} {...props} {...additionalProps} />;
};

export default Link;
