import { LinksHighlightsSubNavItem } from '../types';
import LinkSection from './LinkSection';

export const LinksHighlightsContent = ({
  item,
}: {
  item: LinksHighlightsSubNavItem;
}) => {
  return (
    <div className="w-[274px] desktop-lg:w-[268px] header-scrollbar overflow-y-auto overflow-x-hidden max-h-[calc(100vh_-_var(--header-height)_-_20px)] p-6">
      <LinkSection links={item.links} />
    </div>
  );
};
