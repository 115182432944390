export const MOBILE_CURVE_HEIGHT = 15;
export const TABLET_CURVE_HEIGHT = 91;
export const DESKTOP_SMALL_CURVE_HEIGHT = 156;
export const DESKTOP_LARGE_CURVE_HEIGHT = 204;

export const CURVE_HEIGHT_STYLES = {
  ['--mobile-curve-height' as string]: `${MOBILE_CURVE_HEIGHT}px`,
  ['--tablet-curve-height' as string]: `${TABLET_CURVE_HEIGHT}px`,
  ['--desktop-small-curve-height' as string]: `${DESKTOP_SMALL_CURVE_HEIGHT}px`,
  ['--desktop-large-curve-height' as string]: `${DESKTOP_LARGE_CURVE_HEIGHT}px`,
};
