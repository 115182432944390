'use client';
import { Slot } from '@radix-ui/react-slot';
import {
  CSSProperties,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

const HeaderHeightContext = createContext<[number, (height: number) => void]>([
  0,
  () => null,
]);

export const HeaderHeightContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  return (
    <HeaderHeightContext.Provider value={[headerHeight, setHeaderHeight]}>
      {children}
    </HeaderHeightContext.Provider>
  );
};

export const HeaderHeightListener = ({
  style,
  ...props
}: {
  children: ReactNode;
  style?: CSSProperties;
}) => {
  const [headerHeight] = useContext(HeaderHeightContext);
  const containerStyles = useMemo(
    () => ({
      ...style,
      ['--header-height' as string]: `${headerHeight}px`,
    }),
    [headerHeight, style],
  );

  return <Slot style={containerStyles} {...props} />;
};

export default HeaderHeightContext;
