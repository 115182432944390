'use client';
import { useCallback, useEffect, useState } from 'react';
import { cn } from '@/external/tailwind';
import { CTAComponent } from '../../externalComponent';
import Icon from '../../icon';
import { Button } from '../../button';
import MobileMenu from '../components/MobileMenu';
import { LayoutProps } from '../types';

const Mobile = (props: LayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const initialOverflowValue = document.body.style.overflow;
    document.body.style.overflow = isMenuOpen ? 'hidden' : initialOverflowValue;
    return () => {
      document.body.style.overflow = initialOverflowValue;
    };
  }, [isMenuOpen]);

  const handleMenuClick = useCallback(() => {
    setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen);
  }, [setIsMenuOpen]);

  return (
    <div>
      <div
        className={cn(
          'relative bg-white flex items-center justify-between w-full z-10 border-b border-b-menuBorder',
          'p-6 pt-9 tablet-sm:px-10 tablet-sm:py-7',
        )}
      >
        <CTAComponent {...props.mainLogoCta}>
          <Icon
            icon="sprinklrBurst"
            alt="Sprinklr Logo"
            className="h-8 tablet-sm:hidden"
          />
          <Icon
            icon="sprinklrFullLogo"
            alt="Sprinklr Logo"
            className="hidden shrink-0 tablet-sm:block tablet-sm:h-6"
          />
        </CTAComponent>
        <div className="flex gap-6 tablet-sm:gap-10">
          {props.primaryCta ? (
            <CTAComponent {...props.primaryCta}>
              <Button variant="primary">{props.primaryCta.label}</Button>
            </CTAComponent>
          ) : null}
          <Button variant="bare" size="bare" onClick={handleMenuClick}>
            {isMenuOpen ? (
              <Icon icon="close" alt="close" className="h-6" />
            ) : (
              <Icon icon="hamburgerMenu" alt="Menu" className="h-6" />
            )}
          </Button>
        </div>
      </div>
      {isMenuOpen ? <MobileMenu {...props} /> : null}
    </div>
  );
};

export default Mobile;
