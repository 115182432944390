'use client';
import { useTranslations } from 'next-intl';
import AccordionWidget from '@/components/widgets/accordionWidget';
import { AccordionRow } from '@/components/widgets/accordionWidget/types';

const FAQ_TITLE = 'Frequently Asked Questions';

export const LegacyAccordion = ({
  accordionRows,
}: {
  accordionRows: AccordionRow[];
}) => {
  const t = useTranslations();

  return (
    <AccordionWidget
      title={t(FAQ_TITLE)}
      rows={accordionRows}
      containerClassname="px-0 tablet-sm:px-0"
      faqListClassName="desktop-sm:w-full desktop-lg:w-full"
    />
  );
};
