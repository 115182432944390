import { useMedia } from 'react-use';
import { BREAKPOINTS_IN_NUMBER } from '../../../constants';

export const useIsMobile = () => {
  const isMobile = useMedia(
    `(max-width : ${BREAKPOINTS_IN_NUMBER.tablet - 1}px)`,
    false,
  );

  return isMobile;
};
