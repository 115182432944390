'use client';
import { Fragment, ReactNode } from 'react';
import { useCtaClickHandler } from '@/components/CallToAction/hooks/useCtaClickHandler';
import ModalWidget from '@/components/widgets/modalWidget';
import { ClickActionProps } from '../types';

/** TODO: Convert prop types to a Discriminating union of different schemas narrrowed by
 * the `action` field.
 * */
const ClickAction = ({
  url,
  action,
  children,
  modalContent,
  containerClassName,
  navatticDemoConfig,
  as = 'button',
  isOpenNewTab = false,
}: ClickActionProps & {
  children: ReactNode;
}) => {
  const { handleAction, isModalOpen, setIsModalOpen } = useCtaClickHandler(
    url,
    action,
    navatticDemoConfig,
    isOpenNewTab,
  );

  const As = as;

  return (
    <Fragment>
      {isModalOpen && modalContent ? (
        <ModalWidget
          open={isModalOpen}
          onOpenChange={setIsModalOpen}
          template={modalContent}
        />
      ) : null}
      <As onClick={handleAction} className={containerClassName}>
        {children}
      </As>
    </Fragment>
  );
};

export default ClickAction;
