'use client';

import { useCallback, useContext, useState } from 'react';
import { useRouter } from 'next/navigation';
import HeaderHeightContext from '@/contexts/HeaderHeightContext';
import { CtaAction, NavatticDemoConfig } from '../types';
import useNavatticDemoLoader from './useNavatticDemoLoader';

export const useCtaClickHandler = (
  url?: string,
  action?: CtaAction,
  navatticDemoConfig?: NavatticDemoConfig,
  isOpenNewTab?: boolean,
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [headerHeight] = useContext(HeaderHeightContext);

  const isNavatticScriptLoaded = useNavatticDemoLoader(
    navatticDemoConfig,
    action,
  );

  const router = useRouter();

  const handleAction = useCallback(() => {
    switch (action) {
      case 'SCROLL_TO_ID': {
        const targetElement = url ? document?.getElementById(url.trim()) : null;
        if (targetElement) {
          const elementPosition = targetElement.getBoundingClientRect().top;
          const offsetPosition =
            window.scrollY + elementPosition - headerHeight;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
        break;
      }
      case 'OPEN_MODAL': {
        setIsModalOpen(true);
        break;
      }
      case 'OPEN_NAVATTIC_DEMO': {
        if (isNavatticScriptLoaded && navatticDemoConfig?.popupUrl) {
          const { popupUrl, title } = navatticDemoConfig;
          window.NavatticEmbed.initPopup(popupUrl, { title });
          window.NavatticEmbed.openPopup(popupUrl);
        }
        break;
      }
      case 'NAVIGATE':
        if (!url) break;
        if (isOpenNewTab) {
          window.open(url, '_blank');
        } else {
          // TODO: handle trailing slash logic same as in Link Component
          router.push(url);
        }
        break;
      default:
        break;
    }
  }, [
    action,
    url,
    headerHeight,
    isNavatticScriptLoaded,
    navatticDemoConfig,
    isOpenNewTab,
    router,
  ]);

  return { isModalOpen, setIsModalOpen, handleAction };
};
