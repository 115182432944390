export const LOCALES = [
  'en-US',
  'en-GB',
  'fr',
  'ja-JP',
  'es-001',
  'de-DE',
  'pt-BR',
  'ko',
] as const;

export const DEFAULT_LANGUAGE_CONFIG = {
  lang: 'English (en-US)',
  locale: 'en-US',
  prefixSlug: 'us',
  canonicalLang: 'en-us',
} as const;

export const LANGUAGE_CONFIG = [
  DEFAULT_LANGUAGE_CONFIG,
  {
    lang: 'German (de-DE)',
    locale: 'de-DE',
    prefixSlug: 'de',
    canonicalLang: 'de',
  },
  {
    lang: 'French (fr-FR)',
    locale: 'fr-FR',
    prefixSlug: 'fr',
    canonicalLang: 'fr',
  },
  {
    lang: 'Japanese (ja-JP)',
    locale: 'ja-JP',
    prefixSlug: 'jp',
    canonicalLang: 'ja',
  },
  {
    lang: 'Spanish (es-001)',
    locale: 'es-001',
    prefixSlug: 'es',
    canonicalLang: 'es',
  },
  {
    lang: 'Korean (ko)',
    locale: 'ko',
    prefixSlug: 'kr',
    canonicalLang: 'kr',
  },
  {
    lang: 'Italian (it)',
    locale: 'it',
    prefixSlug: 'it',
    canonicalLang: 'it',
  },
  {
    lang: 'Arabic (ar)',
    locale: 'ar',
    prefixSlug: 'ar',
    canonicalLang: 'ae',
  },
  {
    lang: 'Portuguese (pt-BR)',
    locale: 'pt-BR',
    prefixSlug: 'pt-br',
    canonicalLang: 'pt-br',
  },
  {
    lang: 'English (en-GB)',
    locale: 'en-GB',
    prefixSlug: 'gb',
    canonicalLang: 'en-gb',
  },
] as const;

export const languageToSlugMapping = LANGUAGE_CONFIG.reduce(
  (acc, curr) => {
    acc[curr.lang] = curr.prefixSlug;
    return acc;
  },
  {} as Record<string, string>,
);

export const prefixSlugToLangMapping = LANGUAGE_CONFIG.reduce(
  (acc, curr) => {
    acc[curr.prefixSlug] = curr.lang;
    return acc;
  },
  {} as Record<string, string>,
);

export const localeToSlugMapping = LANGUAGE_CONFIG.reduce(
  (acc, curr) => {
    acc[curr.locale] = curr.prefixSlug;
    return acc;
  },
  {} as Record<string, string>,
);

export const DEFAULT_LANGUAGES = ['English (en-US)'];

export const ALL_LOCALES = LANGUAGE_CONFIG.map(lang => lang.locale);

export const DEFAULT_LOCALE = DEFAULT_LANGUAGE_CONFIG.locale;
