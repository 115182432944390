'use client';

import { ReactNode, createContext, useMemo } from 'react';

import {
  DEFAULT_LANGUAGES,
  DEFAULT_LANGUAGE_CONFIG,
} from '@/constants/locales';

import { Translations } from '@/types';

// TODO: change locale to prefixSlug
type PageContextValue = {
  slug: string;
  locale: string;
  title?: string;
  prefixSlug?: string;
  translations?: Translations;
  productCategory?: string;
};

const defaultPageContextValue: PageContextValue = {
  locale: DEFAULT_LANGUAGE_CONFIG.prefixSlug,
  title: '',
  slug: '',
  prefixSlug: DEFAULT_LANGUAGE_CONFIG.prefixSlug,
  translations: { languagesToRenderIn: DEFAULT_LANGUAGES },
};
export const PageContext = createContext<PageContextValue>(
  defaultPageContextValue,
);

export const PageContextProvider = ({
  value,
  children,
}: {
  value: PageContextValue;
  children: ReactNode;
}) => {
  const memoizedContextValue = useMemo(
    () => ({
      locale: value.locale ?? defaultPageContextValue.prefixSlug,
      title: value.title ?? defaultPageContextValue.title,
      slug: value.slug,
      translations: value.translations ?? defaultPageContextValue.translations,
      productCategory: value.productCategory,
    }),
    [value],
  );
  return (
    <PageContext.Provider value={memoizedContextValue}>
      {children}
    </PageContext.Provider>
  );
};
