const NEXTJS_WEBPAGE_REGEX =
  /^\/(industries|teams|stories|our-story|leadership|unified-cxm|pricing|blog|careers|cx-wise)?(\/.*)?$/;

const DEMO_WEBPAGE_REGEX = /^\/demo\b(?!-days)(\/.*)?\/?$/;

const PRODUCT_WEBPAGE_REGEX = /^\/products\/(?!platform\/(?!ai-plus)).*\/?$/;

// TODO: Remove once migrated completely to NextJS.
export const isNextJsWebsiteLink = (href: string) => {
  return (
    NEXTJS_WEBPAGE_REGEX.test(href) ||
    DEMO_WEBPAGE_REGEX.test(href) ||
    PRODUCT_WEBPAGE_REGEX.test(href)
  );
};
