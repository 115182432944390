import { useMemo } from 'react';
import { ImageProps } from 'next/image';
import { ImageData } from '@/types';
import { cn } from '@/lib/tailwind';
import ImageAssetBlock from '../ImageAssetBlock';

const ImageBlock = ({
  image,
  fill = false,
  className,
  ...imageProps
}: {
  image: ImageData;
  fill?: boolean;
} & Omit<ImageProps, 'src' | 'alt' | 'width' | 'height' | 'fill'>) => {
  const { mobileAsset, tabletImage, defaultAsset } = image;

  const defaultDisplayStyles = useMemo(() => {
    let defaultDisplayStyles = 'block';
    if (mobileAsset) defaultDisplayStyles = 'hidden tablet-sm:block';
    if (tabletImage) defaultDisplayStyles = 'hidden desktop-sm:block';
    return defaultDisplayStyles;
  }, [mobileAsset, tabletImage]);

  const tabletDisplayStyles = useMemo(() => {
    let tabletDisplayStyles = 'block desktop-sm:hidden';
    if (mobileAsset) {
      tabletDisplayStyles = 'hidden tablet-sm:block desktop-sm:hidden';
    }
    return tabletDisplayStyles;
  }, [mobileAsset]);

  const mobileDisplayStyles = 'block tablet-sm:hidden';

  const title = image.title;

  return (
    <>
      <ImageAssetBlock
        asset={defaultAsset}
        fill={fill}
        title={title}
        className={cn(className, defaultDisplayStyles)}
        {...imageProps}
      />
      {mobileAsset && (
        <ImageAssetBlock
          asset={mobileAsset}
          fill={fill}
          title={title}
          className={cn(className, mobileDisplayStyles)}
          {...imageProps}
        />
      )}
      {tabletImage && (
        <ImageAssetBlock
          asset={tabletImage}
          fill={fill}
          title={title}
          className={cn(className, tabletDisplayStyles)}
          {...imageProps}
        />
      )}
    </>
  );
};

export default ImageBlock;
