import { ReactNode } from 'react';
import { cn } from '@/external/tailwind';

const SEOVisibilityWrapper = ({
  children,
  isVisible,
  classname,
}: {
  children: ReactNode;
  isVisible: boolean;
  classname?: string;
}) => {
  return (
    <div className={cn(classname, isVisible ? '' : 'hidden')}>{children}</div>
  );
};

export default SEOVisibilityWrapper;
