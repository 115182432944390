'use client';
import { ReactNode, useCallback } from 'react';
import { Link } from '@/components/Link';
import { CtaData } from '@/types';

type Props = Pick<CtaData, 'url' | 'isOpenNewTab' | 'containerClassName'> & {
  children: ReactNode;
};

const LinkAction = ({
  url,
  isOpenNewTab,
  containerClassName,
  children,
}: Props) => {
  const handleLinkClick = useCallback(
    (event: { preventDefault: () => void }) => {
      if (url.includes('#cookie-preferences')) {
        event.preventDefault();
        window.Osano &&
          window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
      }
    },
    [url],
  );
  return (
    <Link
      href={url ?? ''}
      className={containerClassName}
      isOpenNewTab={isOpenNewTab}
      onClick={handleLinkClick}
    >
      {children}
    </Link>
  );
};

export default LinkAction;
